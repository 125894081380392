import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";
import Logo from "../assets/images/logo.png";

export const Footer = () => {
  return (
    <>
      <footer>
        <div className="container">
          <div className="footer_wrapper">
            <div className="footer__widgets">
              <div className="single_widget">
                <Link to="/home" className="logo">
                  <img src={Logo} alt="logo" />
                </Link>
                <p>Ahol a játék tanulás, a tanulás pedig játék</p>
                <div className="widget_socail">
                  <ul>
                    <li>
                      <Link to="https://www.facebook.com/alphacademy.hu">
                        <FaFacebook />
                      </Link>
                    </li>
                    <li>
                      <Link to="https://www.instagram.com/alphagames.official/">
                        <FaInstagram />
                      </Link>
                    </li>
                    <li>
                      <Link to="https://www.youtube.com/@alphacademyYT">
                        <FaYoutube />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="single_widget">
                <p>
                  Iratkozz hírlevelünkre a legfrissebb hírekért és exkluzív
                  ajánlatokért!
                </p>
                <form action="#">
                  <div className="form_group">
                    <input type="email" placeholder="Ird be az emailcímed" />
                    <button type="submit" className="btn btn_primary">
                      feliratkozás
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="footer_cp">
          <div className="container">
            <div className="footer__cpText">
              <p>© Copyright 2024. All Right Reserved</p>
              <p>
                <a
                  href="https://wordpress.alphacademy.hu/wp-content/uploads/2023/01/Adatkezelesi-tajekoztato.pdf?_gl=1*1v8xj0x*_ga*NTAyMDE4MjI5LjE3MTIzNDc0MTg.*_ga_QBLD7RXGC5*MTcxNjYzOTQ1MS41LjEuMTcxNjYzOTQ4OC4wLjAuMA..*_gcl_au*MTM5MTczNTY5NS4xNzEyMzQ3NDI3"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
