import axios from "../config/axios";
import errorMessages from "../assets/helpers/errorMessages.json"


export const getResults = async (gameId: number) => {
  try {
    const { data } = await axios.get(`/results/${gameId}`);
    return data;
  } catch (err: any) {
    const key = err.data.error_id;
    const parsedJSON = JSON.parse(errorMessages as any);
    alert(parsedJSON[key]);
  }
};
