import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "../redux/hooks";
import CupImage from "../assets/images/cup.webp";
import { getLeaderboardApi } from "../api/leaderboard";
import Navbar from "../components/Navbar";
import BgImage from "../assets/images/bg1.png";
import Logo from "../assets/images/logo.png";

interface LeaderboardEntry {
  place: number;
  first_name: string;
  last_name: string;
  email: string;
  cups: number;
}

export default function Leaderboard() {
  const [leaderboard, setLeaderboard] = useState<LeaderboardEntry[]>([]);
  const [error, setError] = useState<string | null>(null);
  const currentUser = useAppSelector((state) => state.currentUser.currentUser);

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  useEffect(() => {
    async function fetchLeaderboard() {
      try {
        const data = await getLeaderboardApi();
        setLeaderboard(data.leaderboard.slice(0, 10));
      } catch (err) {
        setError("Failed to fetch leaderboard");
        console.error("Error fetching leaderboard: ", err);
      }
    }

    fetchLeaderboard();
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <div
      className="min-h-screen flex flex-col justify-center items-center bg-gradient-to-r from-blue-500 to-white"
      style={{ backgroundImage: `url(${BgImage})`, backgroundSize: "cover" }}
    >
      <header className="absolute top-0 left-0 w-screen flex justify-center items-center">
        <div className="container">
          <div className="heaer_wrapper">
            <Link to="/" className="logo">
              <img src={Logo} alt="logo" />
            </Link>
            <div className="header-nav">
              <div className={`navigation ${isMenuOpen ? "open" : ""}`}>
                <Navbar />
              </div>
              {currentUser && !!currentUser.user ? (
                <button
                  className="btn btn_primary"
                  onClick={() => {
                    localStorage.removeItem("token");
                    localStorage.removeItem("expiresAt");

                    window.location.reload();
                  }}
                >
                  Kilépés
                </button>
              ) : (
                <Link to="/login" className="btn btn_primary">
                  Belépés
                </Link>
              )}
              <div
                className={`moible_bar ${isMenuOpen ? "active" : ""}`}
                onClick={toggleMenu}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="mt-28 mb-12 bg-white p-8 rounded-lg shadow-lg max-w-xs sm:max-w-md md:max-w-2xl lg:max-w-4xl w-full text-center">
        <h1 className="mb-6 text-3xl font-bold">Toplista</h1>
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Játékos
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Kupa
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {leaderboard.map((user, index) => {
              const isCurrentUser =
                currentUser && user.email === currentUser.user.email;
              return (
                <tr key={index} className={isCurrentUser ? "bg-green-100" : ""}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {user.place}. {user.first_name} {user.last_name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap flex items-center">
                    <img src={CupImage} alt="cup" className="w-6 h-6 mr-2" />
                    {user.cups}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {
          <Link
            to="/"
            className="text-blue-600 hover:text-blue-800 font-medium text-lg transition duration-300 ease-in-out mt-8"
          >
            Vissza a főoldalra
          </Link>
        }
      </div>
    </div>
  );
}
