import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const authToken = localStorage.getItem("token");
if (authToken) {
  axiosInstance.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${authToken}`;
}

export default axiosInstance;
