import { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import AppStore from "../assets/images/app-store.png";
import BookIcon from "../assets/images/book.png";
import Calculator from "../assets/images/calculator.svg";
import GameThumb from "../assets/images/game-thumb.svg";
import GooglePlay from "../assets/images/google-play.png";
import HeroAvater from "../assets/images/hero-avater.svg";
import AnimatedIcon from "../assets/images/hero-icon-1.svg";
import Logo from "../assets/images/logo.png";
import WorkThumb from "../assets/images/works.png";
import { homeData } from "../assets/texts/home";
import { Footer } from "../components/Footer";
import Navbar from "../components/Navbar";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getCurrentUserApi } from "../api";
import { setCurrentUser } from "../redux/reducers/currentUserReducer";
import CupImage from "../assets/images/cup.webp";
import SonrisaLogo from "../assets/images/sonrisa-logo.png";
import AlphaAcademyLogo from "../assets/images/alphacademy.png";

export default function Home() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const currentUser = useAppSelector((state) => state.currentUser.currentUser);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleCurrentUser = async () => {
      const currentUserRequest: any = await getCurrentUserApi();

      if (currentUserRequest === "Unauthorized") {
        localStorage.removeItem("token");
        localStorage.removeItem("expiresAt");

        navigate("/login");
      }

      if (currentUserRequest && currentUserRequest.user) {
        dispatch(setCurrentUser(currentUserRequest));
      }
    };

    const token = localStorage.getItem("token");

    if (token) {
      handleCurrentUser();
    }
  }, [dispatch, navigate]);

  return (
    <>
      <header>
        <div className="container">
          <div className="heaer_wrapper">
            <Link to="/" className="logo">
              <img src={Logo} alt="logo" />
            </Link>
            <div className="header-nav">
              <div className={`navigation ${isMenuOpen ? "open" : ""}`}>
                <Navbar></Navbar>
              </div>
              {currentUser && !!currentUser.user ? (
                <button
                  className="btn btn_primary"
                  onClick={() => {
                    localStorage.removeItem("token");
                    localStorage.removeItem("expiresAt");

                    window.location.reload();
                  }}
                >
                  Kilépés
                </button>
              ) : (
                <Link to="/login" className="btn btn_primary">
                  Belépés
                </Link>
              )}
              <div
                className={`moible_bar ${isMenuOpen ? "active" : ""}`}
                onClick={toggleMenu}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </header>
      <main>
        {/* Happy Hour Strip */}
        <section className="happy-hour bg-blue-600 text-white text-center py-2 mb-4 mt-2">
          **Minden reggel 8-tól 9-ig és délután 5-től 6-ig DUPLA KUPA jár minden
          győzelemért!**
        </section>

        {/* Hero */}
        <section className="hero">
          <div className="container-fluid">
            <div className="hero__wrapper">
              <div className="hero__text">
                <h1>
                  Győzd le <br /> az <span>ellenfeleid</span> és nyerj értékes
                  nyereményeket!
                </h1>
                <p>Ahol a játék tanulás, a tanulás pedig játék</p>
                <Link
                  to={currentUser && !!currentUser.user ? "/games" : "/login"}
                  className="btn btn_primary "
                >
                  Játssz most!
                </Link>

                {/* Icons */}
                <img
                  className="animaTedIcon heroIconPotions_one"
                  src={AnimatedIcon}
                  alt="icon"
                />
                <img
                  className="animaTedIcon heroIconPotions_two"
                  src={Calculator}
                  alt="icon"
                />
              </div>

              {/* Icons */}

              <div className="game__illustration">
                <img src={GameThumb} alt="thumb" />
              </div>
            </div>
          </div>
        </section>
        {/* Games */}
        <section className="games">
          <div className="container">
            <div className="game__content">
              <div className="hero__illustration">
                <img src={HeroAvater} alt="herThumb" />
              </div>
              <div className="game__text">
                <div className="section__header">
                  <h2>Nyeremények</h2>
                  <h4>4 db Xbox Series S</h4>
                  <h4>4 db Galaxy Watch 4</h4>
                  <h5>
                    A verseny határideje: <strong>Augusztus 16.</strong>
                  </h5>
                  <p>
                    A nyereményeket a legtöbb kupát összegyűjtő játékosok kapják
                    meg!
                  </p>
                  <h4>Szabályok</h4>
                  <h2>Így nyerhetsz értékes ajándékokat</h2>
                  <p>
                    Minden olyan felhasználót kizárunk a versenyből, aki nem
                    valós személy, vagy nem valós személyt hívott meg.
                  </p>
                  <p>Ezt diákigazolvánnyal kell tudni igazolni.</p>
                  <p>Regisztrálj be, és írd be egy barátod meghívó kódját!</p>
                  <p>Kezdj el játszani, és győzd le az ellenfeleidet!</p>
                  <p>
                    {" "}
                    Hívd meg minél több barátodat, hogy ultra sok kupád legyen!
                  </p>
                  <p> Vedd át a nyereményeket!</p>

                  <h4>Garancia</h4>
                  <p>
                    Teljes körű garanciát vállalunk, hogy a szüleid nem fognak
                    eltiltani ettől a játéktól!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Invite Friends */}
        <section className="invite">
          <div className="container">
            <div className="invite__wrapper text-center bg-white py-10 px-6 rounded-lg shadow-lg">
              <h2 className="text-4xl font-bold mb-4">
                Hívj meg egy barátot és nyerjetek!
              </h2>
              <p className="text-xl mb-4">
                Ha meghívsz valakit és beírja regisztrációnál a profilodnál
                található kódodat, akkor 50 kupát kap egyből, és te is!
              </p>
              <img
                src={CupImage}
                alt="Cup"
                className="w-20 h-20 mx-auto mb-4"
              />
              <p className="text-2xl font-semibold">
                Oszd meg a kódodat és nyerj még több kupát!
              </p>
            </div>
          </div>
        </section>

        {/* Explore */}
        <section className="explore">
          <div className="container">
            <div className="explore__wrapper">
              <div className="section__header">
                <h4>Lehetőségek</h4>
                <h2>Fedezz fel egy új világot</h2>
                <p>
                  Ez a világ legjobb játéka, mindegyik barátod ezzel játszik
                </p>
              </div>
              <div className="explore__content">
                {homeData.exloreCards.map((card) => (
                  <div className="exlore_card" key={card.id}>
                    <div className="card_thumb">
                      <img src={card.imageUrl} alt="cardThumb" />
                    </div>
                    <div className="card_text">
                      <h3>{card.title}</h3>
                      <p>{card.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        {/* Works */}
        <section className="works">
          <div className="container">
            <div className="work__content">
              <div className="work__text">
                <div className="section__header">
                  <h4>Hogyan működik?</h4>
                  <h2>3 nagyon egyszerű lépés</h2>
                </div>
                <div className="work__steps">
                  <ul>
                    <li>
                      <h4>Regisztrálj</h4>
                      <p>Játsz a legmenőbb ingyenes játékkal</p>
                    </li>
                    <li>
                      <h4>Játssz</h4>
                      <p>
                        Nyerj a többi játékos ellen, és szerezz rengeteg kupát
                      </p>
                    </li>
                    <li>
                      <h4>Nyerj</h4>
                      <p>
                        Legyél a ranglétra tetején és zsebeld be az ajándékokat
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="work__illustration">
                <img src={WorkThumb} alt="thumb" />
              </div>
            </div>
          </div>
        </section>
        {/* About Us */}
        <section className="about_us">
          <div className="container">
            <div className="about__wrapper">
              <div className="section__header">
                <h4>Vélemények</h4>
                <h2>Amit rólunk mondtak</h2>
                <p>
                  Fedezd fel a matek titkos szigetét! Hallgasd meg a
                  matekbajnokok történeteit!
                </p>
              </div>
              <div className="about__content">
                {homeData.aboutCards.map((card) => (
                  <div className="about_card" key={card.id}>
                    <div className="card_thumb">
                      <img src={card.imageUrl} alt="cardThumb" />
                    </div>
                    <div className="card_text">
                      <h3>{card.title}</h3>
                      <p>{card.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        {/* Discord */}
        <section className="discord text-center bg-gray-100 py-10">
          <div className="container">
            <img
              src="https://www.freepnglogos.com/uploads/discord-logo-png/discord-logo-logodownload-download-logotipos-1.png"
              alt="Discord logo"
              className="mx-auto mb-4 w-20 h-20"
            />
            <h2 className="text-4xl font-bold mb-4">
              Csatlakozz a Discord szerverünkhöz az újdonságokért!
            </h2>
            <a
              href="https://discord.gg/q5udXcd8vx"
              className="text-xl text-blue-600 underline"
            >
              https://discord.gg/q5udXcd8vx
            </a>
          </div>
        </section>
        {/* CTA */}
        <section className="cta">
          <div className="container-fluid">
            <div className="cta_content">
              {/* animated images */}
              <img
                className="animaTedIcon worksIconPotions_one"
                src={Calculator}
                alt="icon"
              />
              <img
                className="animaTedIcon worksIconPotions_two"
                src={AnimatedIcon}
                alt="icon"
              />
              <img
                className="animaTedIcon worksIconPotions_book"
                src={BookIcon}
                alt="icon"
              />
              {/* animated images */}
              <div className="cta_text">
                <h2>Játssz a telefonodon is!</h2>
                <h3 className="text-center text-white text-2xl">
                  Hamarosan...
                </h3>
                <div className="cta__btns">
                  <Link to="/">
                    <img src={GooglePlay} alt="google-play" />
                  </Link>{" "}
                  <Link to="/">
                    <img src={AppStore} alt="app-store" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Partners */}
        <section className="partners">
          <div className="container text-center py-10">
            <h2 className="text-4xl font-bold mb-6">A támogatóink:</h2>
            <div className="flex justify-center items-center space-x-10">
              <img src={SonrisaLogo} alt="Sonrisa" className="w-60 h-60" />
              <img
                src={AlphaAcademyLogo}
                alt="AlphaAcademy"
                className="w-60 h-60"
              />
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
