import axios from "../config/axios";
import errorMessages from "../assets/helpers/errorMessages.json"


export const startGameApi = async (
  taskCount: number,
  difficulty_level: number
): Promise<any> => {
  try {
    const { data } = await axios.post("/game/start", {
      taskCount: taskCount,
      category: "Calculus",
      difficulty_level: difficulty_level,
    });

    return data;
  } catch (err: any) {
    const key = err.data.error_id;
    const parsedJSON = JSON.parse(errorMessages as any);
    alert(parsedJSON[key]);
  }
};

export const endGameApi = async (gameId: number): Promise<any> => {
  try {
    const { data } = await axios.post(`/game/end/${gameId}`);

    return data;
  } catch (err: any) {
    const key = err.data.error_id;
    const parsedJSON = JSON.parse(errorMessages as any);
    alert(parsedJSON[key]);
  }
};

export const currentGameApi = async (): Promise<any> => {
  try {
    const { data } = await axios.get("/game");

    return data;
  } catch (err: any) {
    const key = err.data.error_id;
    const parsedJSON = JSON.parse(errorMessages as any);
    alert(parsedJSON[key]);
  }
};

export const addThumbUpApi = async (questionId: number): Promise<any> => {
  try {
    const { data } = await axios.post(`/game/thumb_up/${questionId}`);
    return data;
  } catch (err: any) {
    const key = err.data.error_id;
    const parsedJSON = JSON.parse(errorMessages as any);
    alert(parsedJSON[key]);
  }
};

export const addThumbDownApi = async (questionId: number): Promise<any> => {
  try {
    const { data } = await axios.post(`/game/thumb_down/${questionId}`);
    return data;
  } catch (err: any) {
    const key = err.data.error_id;
    const parsedJSON = JSON.parse(errorMessages as any);
    alert(parsedJSON[key]);
  }
};

export const addCupsToUser = async (cups: number): Promise<any> => {
  try {
    const { data } = await axios.post(`/game/add_cups`, {
      cups,
    });
    return data;
  } catch (err: any) {
    const key = err.data.error_id;
    const parsedJSON = JSON.parse(errorMessages as any);
    alert(parsedJSON[key]);
  }
};
