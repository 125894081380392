import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  getUserByEmail,
  getUserByGoogleToken,
  loginApi,
  registerApi,
} from "../api";
import { toast } from "react-toastify";
import { useGoogleLogin } from "@react-oauth/google";
import {
  Button,
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";

import GoogleIconPng from "../assets/images/googleIcon.png";
import BgImage from "../assets/images/bg1.png";

export default function LoginPage() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showInvitePopup, setShowInvitePopup] = useState(false);
  const [inviteCode, setInviteCode] = useState<string>("");
  const [registerEmail, setRegisterEmail] = useState<string>("");
  const [registerFirstName, setRegisterFirstName] = useState<string>("");
  const [registerLastName, setRegisterLastName] = useState<string>("");
  const [registerToken, setRegisterToken] = useState<string>("");
  const [registerExpiresAt, setRegisterExpiresAt] = useState<string>("");
  const [registerParentEmail, setRegisterParentEmail] = useState<string>("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/");
    }
  }, [navigate]);

  const handleLogin = async () => {
    if (!email || !password) {
      toast.error("Kérlek írj be helyes emailt és jelszót!", {
        position: "bottom-right",
      });
      return;
    }

    try {
      const loginRequest = await loginApi({ email, password });

      if (loginRequest.token) {
        localStorage.setItem("token", loginRequest.token);
        localStorage.setItem("expiresAt", `${loginRequest.expiresAt}`);
        navigate("/");
        window.location.reload();
      }
    } catch (e) {
      toast.error("A bejelentkezés nem sikerült. Kérlek próbáld újra.", {
        position: "bottom-right",
      });
    }
  };

  const loginGoogle = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      getUserByGoogleToken(tokenResponse.access_token).then(async (res) => {
        const isRegistered = await getUserByEmail(res.email);

        if (isRegistered) {
          localStorage.setItem("token", tokenResponse.access_token);
          localStorage.setItem(
            "expiresAt",
            (new Date().getTime() + tokenResponse.expires_in).toString()
          );

          navigate("/");
          window.location.reload();
        } else {
          setRegisterEmail(res.email);
          setRegisterFirstName(res.given_name);
          setRegisterLastName(res.family_name);
          setRegisterToken(tokenResponse.access_token);
          setRegisterExpiresAt(
            (new Date().getTime() + tokenResponse.expires_in).toString()
          );
          setShowInvitePopup(true);
        }
      });
    },
    onError: () => {
      toast.error("A bejelentkezés nem sikerült. Kérlek próbáld újra.", {
        position: "bottom-right",
      });
    },
  });

  const handleRegister = async () => {
    if (inviteCode.length > 0) {
      await registerApi({
        email: registerEmail,
        password: "Password12345",
        firstName: registerFirstName,
        lastName: registerLastName,
        grade: 0,
        inviteCode: inviteCode,
        parentEmail: registerParentEmail,
      });
    } else {
      await registerApi({
        email: registerEmail,
        password: "Password12345",
        firstName: registerFirstName,
        lastName: registerLastName,
        parentEmail: registerParentEmail,
      });
    }

    setShowInvitePopup(false);

    localStorage.setItem("token", registerToken);
    localStorage.setItem("expiresAt", registerExpiresAt);
    navigate("/");
    window.location.reload();
  };

  return (
    <>
      <Transition appear show={showInvitePopup}>
        <Dialog
          as="div"
          className="relative z-10 focus:outline-none"
          onClose={() => {}}
        >
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <TransitionChild
                enter="ease-out duration-300"
                enterFrom="opacity-0 transform-[scale(95%)]"
                enterTo="opacity-100 transform-[scale(100%)]"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 transform-[scale(100%)]"
                leaveTo="opacity-0 transform-[scale(95%)]"
              >
                <DialogPanel className="w-full max-w-md rounded-xl bg-white p-6">
                  <DialogTitle
                    as="h3"
                    className="text-2xl font-medium text-black"
                  >
                    További adatok megadása
                  </DialogTitle>
                  <p className="mt-2 text-base text-black">
                    Ha nem vagy 18 éves, akkor add meg a szülőd email címét
                  </p>
                  <input
                    type="text"
                    className="mt-2 p-2 w-full border rounded-md outline-none"
                    placeholder="Szülő email címe..."
                    onChange={(e) => setRegisterParentEmail(e.target.value)}
                    value={registerParentEmail}
                  />
                  <p className="mt-2 text-base text-black">
                    Ha van meghívó kódod, akkor írdd be az alábbi mezőbe
                  </p>
                  <input
                    type="text"
                    className="mt-2 p-2 w-full border rounded-md outline-none"
                    placeholder="Meghívó kód..."
                    onChange={(e) => setInviteCode(e.target.value)}
                    value={inviteCode}
                  />

                  <div className="flex justify-center items-center mt-4">
                    <Button
                      className="inline-flex items-center gap-2 rounded-md bg-green-500 py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-green-600 data-[open]:bg-green-700 data-[focus]:outline-1 data-[focus]:outline-white"
                      onClick={async () => {
                        await handleRegister();
                      }}
                    >
                      Adatok mentése
                    </Button>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
      <div
        style={{
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundImage: `url(${BgImage})`,
          backgroundSize: "cover",
        }}
      >
        <div className="bg-white p-8 rounded-lg shadow-lg w-96">
          <h3 className="text-2xl font-bold mb-4">Bejelentkezés</h3>

          <div className="mb-4">
            <label className="block text-gray-700">Email cím</label>
            <input
              type="email"
              className="mt-1 p-2 w-full border rounded-md"
              placeholder="Add meg az email címed"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">Jelszó</label>
            <input
              type="password"
              className="mt-1 p-2 w-full border rounded-md"
              placeholder="Írd be a jelszavad"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
          </div>

          <div className="flex mb-4 justify-between items-center">
            <div className="flex items-center">
              <input
                type="checkbox"
                className="form-checkbox"
                id="customCheck1"
              />
              <label
                className="ml-2 text-sm text-gray-600"
                htmlFor="customCheck1"
              >
                Emlékezz rám
              </label>
            </div>
            <Link
              to="/register"
              className="text-sm text-blue-500 hover:underline"
            >
              Nincs még fiókod?
            </Link>
          </div>

          <button
            type="button"
            className="w-full py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600"
            onClick={handleLogin}
          >
            Bejelentkezés
          </button>

          <div className="mt-4 flex items-center justify-center w-full">
            <button
              onClick={() => loginGoogle()}
              className="px-3 py-2 border-2 border-black rounded-lg tracking-wide text-black flex items-center transition-colors duration-200 transform hover:bg-gray-200 focus:outline-none"
            >
              <img
                src={GoogleIconPng}
                alt="Google"
                className="inline-block mr-2 w-6 h-6"
              />
              <p className="text-sm text-black">Bejelentkezés Google fiókkal</p>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
