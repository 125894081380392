import Avatar2 from "../images/avatar-2.png";
import Avatar3 from "../images/avatar-3.png";
import Avatar1 from "../images/avater-1.png";
import expoThumb2 from "../images/icons/Pi.svg";
import expoThumb3 from "../images/icons/infinity.svg";
import expoThumb1 from "../images/icons/mathOperations.svg";
import expoThumb4 from "../images/icons/star.svg";

const navItems = [
  { id: 1, title: "Főoldal", link: "/" },
  { id: 3, title: "Rólunk", link: "https://alphacademy.hu/about/" },
];

const navItemsLoggedIn = [
  { id: 1, title: "Főoldal", link: "/" },
  { id: 2, title: "Játékok", link: "/games" },
  { id: 3, title: "Profil", link: "/profile" },
  { id: 4, title: "Toplista", link: "/leaderboard" },
  { id: 5, title: "Szerencsekerék", link: "/spinningwheel" },
];

const aboutCards = [
  {
    id: 1,
    imageUrl: Avatar1,
    title: "K.Bence",
    description:
      "Ez a játék a legjobb! Mindennap játszom vele suli után. Az osztálytársaimmal versenyzünk, hogy ki tud több kupát szerezni, és a matek is könnyebb lett nekem, mióta játszom. Tuti, hogy nyerhetek valami menő ajándékot!",
  },
  {
    id: 2,
    imageUrl: Avatar2,
    title: "Pear",
    description:
      "Nagyon szórakoztató játék, a kérdések érdekesek, és a motiváció is megvan, a nyereménynek köszönhetően. Gyors, az agyat kicsit megtornáztató körök. Tanulás előtt be tudja indítani az ember agyát! Nagyon értékelem, hogy nem szerencsén múlik, így mindenkinek ugyan annyi esélye van. Nekem nagyon tetszik!",
  },
  {
    id: 3,
    imageUrl: Avatar3,
    title: "MenoCsavo2011",
    description:
      "Meghívtam a legjobb barátomat, hogy próbálja ki ezt a játékot, és most mindketten imádjuk! Szerintem ez a legjobb játék, amit valaha játszottam. Nem csak szórakoztató, de tényleg segít abban, hogy jobbak legyünk matekból. Plusz, imádom gyűjteni a kupákat és látni, hogy fejlődöm!",
  },
];

const exloreCards = [
  {
    id: 1,
    imageUrl: expoThumb1,
    title: "Interaktív játékok",
    description:
      "Főbb játékelemek: Mókás és magával ragadó matematikai kaland!",
  },
  {
    id: 2,
    imageUrl: expoThumb2,
    title: "Multiplayer élmény",
    description: "Játssz a barátaid ellen, vagy bárki ellen a világon",
  },
  {
    id: 3,
    imageUrl: expoThumb3,
    title: "Tanulj új dolgokat",
    description: "Tanulj úgy rengeteg új dolgot, hogy észre sem veszed.",
  },
  {
    id: 3,
    imageUrl: expoThumb4,
    title: "Rengeteg ajándék",
    description: "Lépj szintet, gyűjts sok pontot, skineket stb.",
  },
];

export const homeData = {
  navItems,
  navItemsLoggedIn,
  aboutCards,
  exloreCards,
};
