import { AddPointResponse } from "../../types";
import axios from "../config/axios";
import errorMessages from "../assets/helpers/errorMessages.json"

export const answerApi = async (gameId: number, taskId: number, selected_answer: number): Promise<any> => {
  try {
    const { data } = await axios.post(`/answer/${gameId}/${taskId}`, {
        "selected_answer": selected_answer
    });

    return data;
  } catch (err: any) {
    const key = err.data.error_id;
    const parsedJSON = JSON.parse(errorMessages as any);
    alert(parsedJSON[key]);
  }
};
