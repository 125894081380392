import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getCurrentUserApi } from "../api";
import { useDispatch } from "react-redux";
import { setCurrentUser } from "../redux/reducers/currentUserReducer";

export default function AuthWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const handleCurrentUser = async () => {
      const currentUserRequest = await getCurrentUserApi();

      if (currentUserRequest && currentUserRequest.user) {
        dispatch(setCurrentUser(currentUserRequest));
      }
    };

    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }

    handleCurrentUser();
  }, [dispatch, navigate]);

  return <>{children}</>;
}
