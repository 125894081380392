import { randomInt } from "crypto";

const names = [
  "Balint",
  "Gergo",
  "David",
  "Mate",
  "Levente",
  "Attila",
  "Zoltan",
  "Tamas",
  "Peter",
  "Laszlo",
  "Istvan",
  "Gabor",
  "Csaba",
  "Balazs",
  "Andras",
  "Krisztian",
  "Miklos",
  "Norbert",
  "Robert",
  "Szabolcs",
  "Tibor",
  "Viktor",
  "Adam",
  "Gyorgy",
  "Janos",
  "Kristof",
  "Lajos",
  "Marton",
  "Nandor",
  "Oliver",
  "Anna",
  "Eszter",
  "Kata",
  "Krisztina",
  "Eva",
  "Judit",
  "Gabriella",
  "Ildiko",
  "Agnes",
  "Zsuzsanna",
  "Orsolya",
  "Reka",
  "Noemi",
  "Szilvia",
  "Viktoria",
  "Ferenc",
  "Erik",
  "Bence",
  "Zsolt",
  "Henrik",
  "Zsombor",
  "Aron",
  "Gyula",
  "Imre",
  "Koppany",
  "Lorinc",
  "Odon",
  "Pal",
  "Roland",
  "Sandor",
  "Vilmos",
  "Zalán",
  "Vivien",
  "Dorina",
  "Zita",
  "Katalin",
  "Janka",
  "Emese",
  "Beatrix",
  "Melinda",
  "Aniko",
  "Blanka",
  "Emilia",
  "Ilona",
  "Lilla",
  "Marta",
  "Monika",
  "Nikoletta",
  "Piroska",
  "Sarolta",
  "Tunde",
  "Veronika",
  "Amanda",
  "Brian",
  "Christopher",
  "Daniel",
  "Edward",
  "Fiona",
  "George",
  "Hannah",
  "Isabella",
  "Jack",
  "Katherine",
  "Lucas",
  "Michael",
  "Nathan",
  "Olivia",
  "Paul",
  "Quinn",
  "Richard",
  "Sarah",
  "Thomas",
  "Ursula",
  "Victoria",
  "William",
  "Xavier",
  "Yvonne",
  "Zachary"
];

export const generateOpponentName = () => {
  const name = names[Math.floor(Math.random() * names.length)];
  const number = Math.floor(Math.random() * 1000) + 1; 
  const isNumberFirst = Math.random() > 0.5;

  return isNumberFirst ? `${number}${name}` : `${name}${number}`;
};
