import React from "react";
import { useAppSelector } from "../redux/hooks";
import { Link } from "react-router-dom";
import CupImage from "../assets/images/cup.webp";
import BgImage from "../assets/images/bg1.png";

interface Level {
  name: string;
  maxCups: number;
  color: string;
}

const levels: Level[] = [
  { name: "Fa", maxCups: 200, color: "text-brown-700" },
  { name: "Bronz", maxCups: 400, color: "text-bronze-700" },
  { name: "Ezüst", maxCups: 600, color: "text-silver-700" },
  { name: "Arany", maxCups: 800, color: "text-gold-500" },
  { name: "Platina", maxCups: 1000, color: "text-platinum-500" },
  { name: "Elit", maxCups: 1200, color: "text-elite-500" },
  { name: "Bajnok", maxCups: 1400, color: "text-champion-500" },
  { name: "Einstein", maxCups: 1600, color: "text-einstein-500" },
  { name: "Félisten", maxCups: 1800, color: "text-semi-god-500" },
  { name: "Matek Isten", maxCups: 2000, color: "text-math-god-500" },
  { name: "Tudós", maxCups: 2200, color: "text-scientist-500" },
  { name: "Zseni", maxCups: 2400, color: "text-genius-500" },
  { name: "Mester", maxCups: 2600, color: "text-master-500" },
  { name: "Nagyúr", maxCups: 2800, color: "text-overlord-500" },
  { name: "Legenda", maxCups: 3000, color: "text-legend-500" },
  { name: "Szuperhős", maxCups: 3200, color: "text-superhero-500" },
  { name: "Hős", maxCups: 3400, color: "text-hero-500" },
  { name: "Felsőbbrendű", maxCups: 3600, color: "text-supreme-500" },
  { name: "Mesterelme", maxCups: 3800, color: "text-mastermind-500" },
  { name: "Uralkodó", maxCups: 4000, color: "text-ruler-500" },
  { name: "Király", maxCups: 4200, color: "text-king-500" },
  { name: "Császár", maxCups: 4400, color: "text-emperor-500" },
  { name: "Isteni", maxCups: 4600, color: "text-divine-500" },
  { name: "Mindenható", maxCups: 4800, color: "text-almighty-500" },
  { name: "Végtelen", maxCups: 5000, color: "text-infinite-500" },
  { name: "Kupagyűjtő", maxCups: 10000, color: "text-ruler-500" },
  { name: "Profi kupagyűjtő", maxCups: 20000, color: "text-ruler-500" },
  { name: "Mester kupagyűjtő", maxCups: 30000, color: "text-ruler-500" },
  { name: "Kupaőrült", maxCups: 40000, color: "text-ruler-500" },
  { name: "Kupabirodalom", maxCups: 50000, color: "text-ruler-500" },
  { name: "Kupakirályság", maxCups: 60000, color: "text-ruler-500" }
];

interface Badge {
  name: string;
  criteria: (user: any) => boolean;
  emoji: string;
}

const badges: Badge[] = [
  { name: "Első Győzelem", criteria: user => user?.userStat?.all_wins >= 1, emoji: "🏆" },
  { name: "Száz Kupa", criteria: user => user?.userStat?.all_cups >= 100, emoji: "🥇" },
  { name: "Ötszáz Kupa", criteria: user => user?.userStat?.all_cups >= 500, emoji: "🥈" },
  { name: "Ezer Kupa", criteria: user => user?.userStat?.all_cups >= 1000, emoji: "🏅" },
  { name: "Ötezer Kupa", criteria: user => user?.userStat?.all_cups >= 5000, emoji: "💎" },
  { name: "Tíz Győzelem", criteria: user => user?.userStat?.all_wins >= 10, emoji: "🥉" },
  { name: "Ötven Győzelem", criteria: user => user?.userStat?.all_wins >= 50, emoji: "🎖️" },
  { name: "Száz Győzelem", criteria: user => user?.userStat?.all_wins >= 100, emoji: "🎗️" },
  { name: "Mester Feladatmegoldó", criteria: user => user?.userStat?.current_stat >= 1000, emoji: "🚀" },
  { name: "Legenda Feladatmegoldó", criteria: user => user?.userStat?.current_stat >= 5000, emoji: "⭐" },
  { name: "Ötszáz Win", criteria: user => user?.userStat?.all_wins >= 500, emoji: "🏆" },
  { name: "Ezer Win", criteria: user => user?.userStat?.all_wins >= 1000, emoji: "🏅" },
  { name: "Ötezer Win", criteria: user => user?.userStat?.all_wins >= 5000, emoji: "🎗️" },
  { name: "Tízezer Win", criteria: user => user?.userStat?.all_wins >= 10000, emoji: "🎖️" },
  { name: "Easter Egg", criteria: user => user?.userStat?.user_id === 999, emoji: "🥚" }
];

function getUserLevel(cups: number): Level {
  return levels.find(level => cups <= level.maxCups) || levels[levels.length - 1];
}

function getNextLevel(cups: number): Level | null {
  return levels.find(level => cups < level.maxCups && cups < (levels[levels.indexOf(level) + 1]?.maxCups || Infinity)) || null;
}

export default function Profile() {
  const currentUser = useAppSelector((state) => state.currentUser.currentUser);

  const userCups: number = currentUser?.userStat?.all_cups || 0;
  const userLevel: Level = getUserLevel(userCups);
  const nextLevel: Level | null = getNextLevel(userCups);

  const progressToNextLevel = nextLevel ? (userCups / nextLevel.maxCups) * 100 : 100;

  return (
    <div className="min-h-screen flex flex-col justify-center items-center" style={{ backgroundImage: `url(${BgImage})`, backgroundSize: 'cover' }}>
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-xs sm:max-w-md md:max-w-2xl lg:max-w-4xl w-full">
        <div className="flex flex-col items-center text-center">
          <h1 className={`mb-6 text-3xl font-bold ${userLevel.color}`}>
            {currentUser ? `${userLevel.name} ${currentUser.user.first_name}` : "Ismeretlen"}{" "}
            profilja
          </h1>
          <h2 className="mb-2 text-lg font-medium">
            Email: {currentUser ? currentUser.user.email : "Ismeretlen"}
          </h2>
          <h2 className="mb-6 text-lg font-medium">
            A Te meghívó kódod:{" "}
            {currentUser ? currentUser.user.invite_code : "Ismeretlen"}
          </h2>
          <div className="w-full">
            <div className="mb-8">
              <h3 className="text-md font-semibold">
                Helyesen megoldott feladatok:
              </h3>
              <p className="text-lg">
                {currentUser && currentUser.userStat
                  ? currentUser.userStat.current_stat
                  : 0}
              </p>
            </div>
          </div>
          <div className="mb-12">
            <h3 className="text-md font-semibold">Nyertes játékok száma:</h3>
            <p className="text-lg">
              {currentUser && currentUser.userStat
                ? currentUser.userStat.all_wins
                : 0}
            </p>
          </div>
          <div className="flex items-center mb-8">
            <img src={CupImage} alt="cup" className="w-8 h-8 mr-4" />
            <h3 className="text-md font-semibold">
              {currentUser && currentUser.userStat
                ? currentUser.userStat.all_cups
                : 0}
            </h3>
          </div>
          {nextLevel && (
            <div className="mb-12">
              <h3 className="text-md font-semibold">Következő szint:</h3>
              <div className="w-full bg-gray-200 rounded-full h-2.5">
                <div
                  className="bg-blue-600 h-2.5 rounded-full"
                  style={{ width: `${progressToNextLevel}%` }}
                ></div>
              </div>
              <p className="text-sm mt-2">{userCups} / {nextLevel.maxCups} kupa</p>
            </div>
          )}
          <div className="mt-8 w-full">
            <h3 className="text-md font-semibold mb-4">Badge-ek:</h3>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
              {badges.map((badge, index) => {
                const achieved = badge.criteria(currentUser);
                return (
                  <div key={index} className="flex flex-col items-center">
                    <div
                      className={`w-12 h-12 flex items-center justify-center text-2xl ${achieved ? "animate-bounce" : "grayscale text-gray-400"}`}
                    >
                      {badge.emoji}
                    </div>
                    <p className={`text-sm ${achieved ? "text-black" : "text-gray-400"}`}>
                      {badge.name}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
          <Link
            to="/"
            className="text-blue-600 hover:text-blue-800 font-medium text-lg transition duration-300 ease-in-out mt-8"
          >
            Vissza a főoldalra
          </Link>
        </div>
      </div>
    </div>
  );
}
