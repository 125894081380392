import { homeData } from "../assets/texts/home";
import { useAppSelector } from "../redux/hooks";
import CupImage from "../assets/images/cup.webp";



export default function Navbar() {
  const currentUser = useAppSelector(state => state.currentUser.currentUser);

  const navbarItems = currentUser && currentUser.userStat
    ? [
        ...homeData.navItemsLoggedIn,
        { id: 999, title: (
            <div className="flex items-center">
              <img src={CupImage} alt="Cups" className="mr-2" style={{ width: '24px', height: '24px' }} />
              {currentUser.userStat.all_cups}
            </div>
          ),
          link: "/profile"
        }
      ]
    : homeData.navItems; // If user is not logged in, show the default navbar items

  return (
    <nav>
      <ul>
        {navbarItems.map((item) => (
          <li key={item.id}>
            <a href={item.link} className="flex items-center">
              {typeof item.title === 'string' ? item.title : item.title}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
}