import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { registerApi } from "../api";
import { toast } from "react-toastify";
import BgImage from "../assets/images/bg1.png";

export default function Register() {
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [parentEmail, setParentEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [grade, setGrade] = useState(0);
  const [inviteCode, setInviteCode] = useState("");

  const handleRegister = async () => {
    if (!firstName || !lastName || !email || !password || !confirmPassword) {
      toast.error("Kérlek, töltsd ki az összes mezőt!", {
        position: "bottom-right",
      });
      return;
    }

    if (!parentEmail) {
      toast.error("Csak úgy nyerhetsz, ha megadod a szülőd email címét!", {
        position: "bottom-right",
      });
      return;
    }

    if (password !== confirmPassword) {
      toast.error("A két jelszó nem egyezik!", {
        position: "bottom-right",
      });
      return;
    }

    try {
      const registerRequest = await registerApi({
        email,
        parentEmail,
        password,
        firstName,
        lastName,
        grade,
        inviteCode,
      });

      if (registerRequest) {
        toast.success("A regisztráció sikerült, jelentkezz be!", {
          position: "bottom-right",
        });
        navigate("/login");
      }
    } catch (e) {
      toast.error("A regisztráció nem sikerült. Kérlek, próbáld újra", {
        position: "bottom-right",
      });
    }
  };

  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: `url(${BgImage})`, // Set background image
        backgroundSize: 'cover', // Ensure the image covers the entire area
      }}
    >
      <div className="bg-white p-8 pt-3 pb-3 rounded-lg shadow-lg w-96">
        <h3 className="text-2xl font-bold mb-4">Regisztráció</h3>
        <div className="w-full flex items-center gap-2 mb-4">
          <div className="w-1/2">
            <label className="block text-gray-700">Keresztnév</label>
            <input
              type="text"
              className="mt-1 p-2 w-full border rounded-md"
              placeholder="Add meg a keresztneved"
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName}
            />
          </div>
          <div className="w-1/2">
            <label className="block text-gray-700">Vezetéknév</label>
            <input
              type="text"
              className="mt-1 p-2 w-full border rounded-md"
              placeholder="Add meg a vezetékneved"
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
            />
          </div>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Email cím</label>
          <input
            type="email"
            className="mt-1 p-2 w-full border rounded-md"
            placeholder="Add meg az email címed"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">
            Szülő email cím (18 év alatt)
          </label>
          <input
            type="email"
            className="mt-1 p-2 w-full border rounded-md"
            placeholder="Add meg az email címed"
            onChange={(e) => setParentEmail(e.target.value)}
            value={parentEmail}
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Jelszó</label>
          <input
            type="password"
            className="mt-1 p-2 w-full border rounded-md"
            placeholder="Írd be a jelszavad"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Jelszó újra</label>
          <input
            type="password"
            className="mt-1 p-2 w-full border rounded-md"
            placeholder="Írd be újra a jelszavad"
            onChange={(e) => setConfirmPassword(e.target.value)}
            value={confirmPassword}
          />
        </div>

        <div className="w-full flex items-center gap-2 mb-4">
          <div className="w-1/2">
            <label className="block text-gray-700">Osztály</label>
            <input
              type="number"
              className="mt-1 p-2 w-full border rounded-md"
              placeholder="Add meg az osztályod"
              min={1}
              max={12}
              onChange={(e) => setGrade(parseInt(e.target.value))}
              value={grade}
            />
          </div>
          <div className="w-1/2">
            <label className="block text-gray-700">Meghívó kód</label>
            <input
              type="text"
              className="mt-1 p-2 w-full border rounded-md"
              placeholder="A kapott meghívó kód"
              onChange={(e) => setInviteCode(e.target.value)}
              value={inviteCode}
            />
          </div>
        </div>

        <button
          type="button"
          className="w-full py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          onClick={handleRegister}
        >
          Regisztráció
        </button>

        <div className="mt-4 w-full">
          <Link
            to="/login"
            className="text-sm text-blue-500 hover:underline text-center w-full block"
          >
            Már van fiókod? Bejelentkezés
          </Link>
        </div>
      </div>
    </div>
  );
}
