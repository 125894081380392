import React, { useEffect, useState } from "react";
import { uploadTask } from "../api";

export default function UploadTask() {
  const [taskText, setTaskText] = useState<string>("");
  const [classLevel, setClassLevel] = useState<number>(6);
  const [difficulty, setDifficulty] = useState<number>(1);
  const [timeLimit, setTimeLimit] = useState<number>(60);
  const [goodAnswer, setGoodAnswer] = useState<string>("");
  const [badAnswer1, setBadAnswer1] = useState<string>("");
  const [badAnswer2, setBadAnswer2] = useState<string>("");
  const [badAnswer3, setBadAnswer3] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);

  const handleUpload = async () => {
    if (!taskText || !goodAnswer || !badAnswer1 || !badAnswer2 || !badAnswer3) {
      alert("Please enter all the fields");
      return;
    }

    setLoading(true);

    const resp = await uploadTask(
      taskText,
      goodAnswer,
      badAnswer1,
      badAnswer2,
      badAnswer3,
      difficulty,
      timeLimit,
      classLevel
    );

    setLoading(false);

    if (resp) {
      setTaskText("");
      setClassLevel(6);
      setDifficulty(1);
      setTimeLimit(60);
      setGoodAnswer("");
      setBadAnswer1("");
      setBadAnswer2("");
      setBadAnswer3("");

      alert("Task uploaded successfully");
    }
  };

  return (
    <div className="w-screen h-screen flex flex-col justify-center items-center">
      {loading && (
        <div className="absolute bg-black bg-opacity-50 w-screen h-screen z-10"></div>
      )}
      {loading ? (
        <div className="bg-white p-8 rounded-lg shadow-lg z-20">
          <h1 className="text-center">Uploading...</h1>
        </div>
      ) : (
        <>
          <h1 className="text-center text-3xl font-bold">Upload Task</h1>

          <div className="flex flex-col lg:flex-row items-center gap-5 mt-5">
            <textarea
              className="w-96 h-48 border p-2 rounded-md"
              placeholder="Task text"
              onChange={(e) => setTaskText(e.target.value)}
              value={taskText}
            ></textarea>

            <div className="flex flex-col gap-4 mt-4">
              <div>
                <label>Class level:</label>
                <input
                  type="number"
                  className="border p-2 rounded-md"
                  onChange={(e) => setClassLevel(Number(e.target.value))}
                  value={classLevel}
                />
              </div>

              <div>
                <label>Difficulty:</label>
                <input
                  type="number"
                  className="border p-2 rounded-md"
                  onChange={(e) => setDifficulty(Number(e.target.value))}
                  value={difficulty}
                />
              </div>

              <div>
                <label>Time Limit:</label>
                <input
                  type="number"
                  className="border p-2 rounded-md"
                  onChange={(e) => setTimeLimit(Number(e.target.value))}
                  value={timeLimit}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col lg:flex-row gap-4 mt-6">
            <div>
              <label>Good answer:</label>
              <input
                type="text"
                className="border p-2 rounded-md"
                onChange={(e) => setGoodAnswer(e.target.value)}
                value={goodAnswer}
              />
            </div>

            <div>
              <label>Bad answer 1:</label>
              <input
                type="text"
                className="border p-2 rounded-md"
                onChange={(e) => setBadAnswer1(e.target.value)}
                value={badAnswer1}
              />
            </div>

            <div>
              <label>Bad answer 2:</label>
              <input
                type="text"
                className="border p-2 rounded-md"
                onChange={(e) => setBadAnswer2(e.target.value)}
                value={badAnswer2}
              />
            </div>

            <div>
              <label>Bad answer 3:</label>
              <input
                type="text"
                className="border p-2 rounded-md"
                onChange={(e) => setBadAnswer3(e.target.value)}
                value={badAnswer3}
              />
            </div>
          </div>

          <button
            type="submit"
            className="mt-12 bg-blue-500 text-white p-2 rounded-md px-10"
            onClick={handleUpload}
          >
            Upload
          </button>
        </>
      )}
    </div>
  );
}
