import axios from "../config/axios";
import errorMessages from "../assets/helpers/errorMessages.json"


export const uploadTask = async (
  question: string,
  goodAnswer: string,
  badAnswer1: string,
  badAnswer2: string,
  badAnswer3: string,
  taskDifficulty: number,
  taskTimeLimit: number,
  taskLevel: number
): Promise<any> => {
  try {
    const answers = [
      {
        answer: goodAnswer,
        is_correct: true,
      },
      {
        answer: badAnswer1,
        is_correct: false,
      },
      {
        answer: badAnswer2,
        is_correct: false,
      },
      {
        answer: badAnswer3,
        is_correct: false,
      },
    ];

    for (let i = answers.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [answers[i], answers[j]] = [answers[j], answers[i]];
    }

    const { data } = await axios.post(`/task`, {
      taskCategory: "Calculus",
      taskDescription: "Párbajkérdések",
      question,
      answers,
      taskDifficulty,
      taskTimeLimit,
      taskLevel,
    });

    return data;
  } catch (err: any) {
    const key = err.data.error_id;
    const parsedJSON = JSON.parse(errorMessages as any);
    alert(parsedJSON[key]);
  }
};
