import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getResults } from "../api";
import Confetti from "react-confetti";
import { generateOpponentName } from "../assets/helpers/generateOpponentName";
import WinStreak from "../assets/images/flames-icon.svg";
import CupImage from "../assets/images/cup.webp";
import { useAppSelector } from "../redux/hooks";
import BgImage from "../assets/images/bg1.png";
import Navbar from "../components/Navbar";
import Logo from "../assets/images/logo.png";

type Result = {
  is_correct: boolean;
};

const isHappyHour = () => {
  const now = new Date();
  const startAfternoon = new Date();
  const endAfternoon = new Date();
  const startMorning = new Date();
  const endMorning = new Date();

  startAfternoon.setHours(17, 0, 0);
  endAfternoon.setHours(18, 0, 0);

  startMorning.setHours(8, 0, 0);
  endMorning.setHours(9, 0, 0);

  return (
    (now.getTime() >= startAfternoon.getTime() &&
      now.getTime() <= endAfternoon.getTime()) ||
    (now.getTime() >= startMorning.getTime() &&
      now.getTime() <= endMorning.getTime())
  );
};

export default function Results() {
  const { gameId } = useParams<{ gameId: string }>();

  const currentUser = useAppSelector((state) => state.currentUser.currentUser);

  const [difficultyLevel, setDifficultyLevel] = useState<number>(1);

  const [results, setResults] = useState<Result[]>([]);
  const [botResults, setBotResults] = useState<Result[]>([]);
  const [botPoints, setBotPoints] = useState<number>(0);
  const [userPoints, setUserPoints] = useState<number>(0);
  const [opponentName, setOpponentName] = useState<string>("");
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const [isWin, setIsWin] = useState<boolean>(false);
  const [isTie, setIsTie] = useState<boolean>(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const winStreak = parseInt(localStorage.getItem("winStreak") || "0", 10);

  const isLongGame = results.length === 6;

  let winPoints = isHappyHour() ? (isLongGame ? 12 : 6) : isLongGame ? 6 : 3;
  let losePoints = isLongGame ? 2 : 1;
  let tiePoints = isHappyHour() ? (isLongGame ? 4 : 2) : isLongGame ? 2 : 1;

  if (difficultyLevel === 2) {
    winPoints += 2;
    losePoints += 2;
    tiePoints += 2;
  } else if (difficultyLevel === 3) {
    winPoints += 4;
    losePoints += 4;
    tiePoints += 4;
  }

  useEffect(() => {
    setOpponentName(generateOpponentName());
  }, []);

  useEffect(() => {
    const fetchResults = async () => {
      if (gameId) {
        const data = await getResults(parseInt(gameId));
        if (data.results) {
          setResults(data.results);
          setBotResults(data.botResults);
          setBotPoints(data.botPoints);
          setUserPoints(data.userPoints);
          setDifficultyLevel(data.difficulty_level);

          let currentStreak = parseInt(
            localStorage.getItem("winStreak") || "0",
            10
          );

          if (data.userPoints > data.botPoints) {
            setIsWin(true);
            setIsTie(false);
            currentStreak += 1;
          } else if (data.userPoints === data.botPoints) {
            setIsTie(true);
            setIsWin(false);
          } else {
            setIsWin(false);
            setIsTie(false);
            currentStreak = 0;
          }

          localStorage.setItem("winStreak", currentStreak.toString());
        }
      }
    };

    fetchResults();
  }, [gameId]);

  if (!gameId) {
    return <div>Nincs megadva játékazonosító</div>;
  }

  return (
    <div
      className="min-h-screen flex flex-col justify-center items-center bg-gradient-to-r from-blue-500 to-white"
      style={{ backgroundImage: `url(${BgImage})`, backgroundSize: "cover" }}
    >
      <header className="absolute top-0 left-0 w-screen flex justify-center items-center">
        <div className="container">
          <div className="heaer_wrapper">
            <Link to="/" className="logo">
              <img src={Logo} alt="logo" />
            </Link>
            <div className="header-nav">
              <div className={`navigation ${isMenuOpen ? "open" : ""}`}>
                <Navbar />
              </div>
              {currentUser && !!currentUser.user ? (
                <button
                  className="btn btn_primary"
                  onClick={() => {
                    localStorage.removeItem("token");
                    localStorage.removeItem("expiresAt");

                    window.location.reload();
                  }}
                >
                  Kilépés
                </button>
              ) : (
                <Link to="/login" className="btn btn_primary">
                  Belépés
                </Link>
              )}
              <div
                className={`moible_bar ${isMenuOpen ? "active" : ""}`}
                onClick={toggleMenu}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </header>
      {isWin && (
        <Confetti width={window.innerWidth} height={window.innerHeight} />
      )}

      <div className="bg-white p-8 rounded-lg shadow-lg max-w-xl w-full relative mt-24 mb-8">
        <div className="flex flex-col items-center text-center">
          <h1 className="mb-6 text-4xl font-bold">
            Játék eredmények{" "}
            {isTie
              ? "Döntetlen!"
              : isWin
              ? "- Te nyertél!"
              : "- Nem te nyertél!"}
            {!isWin && !isTie && <span>&#128557;</span>}
          </h1>
          <h2 className="mb-4 text-lg font-medium">
            A Te pontjaid: {userPoints}
          </h2>
          <h2 className="mb-4 text-lg font-medium">
            {opponentName} pontjai: {botPoints}
          </h2>

          <div className="w-full overflow-x-auto">
            <table className="w-full mt-6">
              <thead>
                <tr className="text-lg font-semibold">
                  <th>Kérdés száma</th>
                  <th>A Te válaszod</th>
                  <th>{opponentName} válasza</th>
                </tr>
              </thead>
              <tbody>
                {results.map((result, index) => (
                  <tr key={index} className="text-lg">
                    <td className="py-2 text-center">{index + 1}.</td>
                    <td className="py-2 text-center">
                      {result.is_correct ? "Helyes" : "Nem helyes"}
                    </td>
                    <td className="py-2 text-center">
                      {botResults[index].is_correct ? "Helyes" : "Nem helyes"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {winStreak > 0 && (
            <div className="absolute bottom-0 right-0 mb-4 mr-4 flex items-center bg-red-100 text-red-600 rounded-full p-1 text-s">
              <img
                src={WinStreak}
                alt="Winstreak Fire"
                className="w-4 h-4 mr-2"
              />
              <span>
                {winStreak}{" "}
                {winStreak === 1 ? "győzelem" : "győzelem zsinórban"}
              </span>
            </div>
          )}
        </div>

        {isWin && (
          <div className="absolute bottom-0 left-0 ml-2 mb-2 flex items-center text-green-500">
            <img src={CupImage} alt="Cups" className="w-6 h-6 mr-2" />
            <span className="font-bold">
              +{winPoints} kupa {isHappyHour() && "(Happy Hour)"}
            </span>
          </div>
        )}
        {!isWin && !isTie && (
          <div className="absolute bottom-0 left-0 ml-2 mb-2 flex items-center text-red-500">
            <img src={CupImage} alt="Cups" className="w-6 h-6 mr-2" />
            <span className="font-bold">-{losePoints} kupa</span>
          </div>
        )}
        {isTie && (
          <div className="absolute bottom-0 left-0 ml-2 mb-2 flex items-center text-blue-500">
            <img src={CupImage} alt="Cups" className="w-6 h-6 mr-2" />
            <span className="font-bold">
              +{tiePoints} kupa {isHappyHour() && "(Happy Hour)"}
            </span>
          </div>
        )}

        <div className="flex flex-col items-center mt-10">
          <Link
            to="/"
            className="mb-4 px-7 py-3 rounded-full bg-blue-500 text-white hover:bg-blue-300 transition duration-300 ease-in-out"
          >
            Vissza a főoldalra
          </Link>

          <Link
            to="/games"
            className="px-7 py-3 rounded-full bg-blue-500 text-white hover:bg-blue-300 transition duration-300 ease-in-out"
          >
            Új játék
          </Link>
        </div>

        <div className="flex flex-col items-center bg-gray-100 p-4 rounded-lg shadow-inner mt-8">
          <h2 className="text-lg font-medium">
            A Te meghívó kódod:{" "}
            <span className="font-bold text-blue-600">
              {currentUser ? currentUser.user.invite_code : "Ismeretlen"}
            </span>
          </h2>
          <p className="text-sm text-gray-700 mt-2 text-center">
            Ez a Te kódod, ha ezt használja más a regisztrációnál, akkor
            mindketten 50-50 kupát kaptok.
          </p>
        </div>
      </div>
    </div>
  );
}
