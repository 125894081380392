import React from "react";
import ReactDOM from "react-dom/client";
import ReactGA from "react-ga4";
import { Provider } from "react-redux";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "./assets/fonts/stylesheet.css";
import AuthWrapper from "./components/AuthWrapper";
import "./config/i18n"; // Ensure the path is correct
import "./index.css";
import { store } from "./redux/store"; // Ensure the path is correct
import reportWebVitals from "./reportWebVitals"; // Ensure the path is correct
import Error from "./routes/Error"; // Ensure the path is correct
import Games from "./routes/Games"; // Ensure the path is correct
import Home from "./routes/Home";
import Login from "./routes/Login"; // Ensure the path is correct
import Profile from "./routes/Profile";
import Register from "./routes/Register";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Results from "./routes/Results";
import Leaderboard from "./routes/LeaderBoard";
import Navbar from "./components/Navbar";
import SpinningWheel from "./routes/SpinningWheel";
import UploadTask from "./routes/UploadTask";

const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

ReactGA.initialize("G-J60YB199DE");

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/uploadTask",
    element: <UploadTask />,
  },
  {
    path: "/spinningwheel",
    element: (
      <AuthWrapper>
        <SpinningWheel />
      </AuthWrapper>
    ),
  },
  {
    path: "/results/:gameId",
    element: (
      <AuthWrapper>
        <Results />
      </AuthWrapper>
    ),
  },
  {
    path: "/login",
    element: (
      <GoogleOAuthProvider clientId={googleClientId!}>
        <Login />
      </GoogleOAuthProvider>
    ),
    errorElement: <Error />,
  },
  {
    path: "/profile",
    element: (
      <AuthWrapper>
        <Profile />
      </AuthWrapper>
    ),
  },
  {
    path: "/games",
    element: (
      <AuthWrapper>
        <Games />
      </AuthWrapper>
    ),
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/leaderboard",
    element: (
      <AuthWrapper>
        <Leaderboard />
      </AuthWrapper>
    ),
  },
]);

root.render(
  <React.StrictMode>
    {process.env.REACT_APP_IS_MAINTENANCE === "true" ? (
      <div className="flex justify-center items-center h-screen">
        <h1 className="text-4xl font-bold">Az oldal karbantartás alatt van</h1>
      </div>
    ) : (
      <Provider store={store}>
        <RouterProvider router={router} />
        <ToastContainer />
      </Provider>
    )}
  </React.StrictMode>
);

const SendAnalytics = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
};

reportWebVitals(SendAnalytics);
