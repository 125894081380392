import {
  CurrentUserResponse,
  LoginRequest,
  LoginResponse,
  RegisterRequest,
  RegisterResponse,
} from "../../types";
import axios from "../config/axios";
import errorMessages from "../assets/helpers/errorMessages.json"


export const loginApi = async ({
  email,
  password,
}: LoginRequest) => {
  try {
    const { data } = await axios.post("/auth/login", {
      email,
      password,
    });

    return data;
  } catch (err: any) {
    const key = err.data.error_id;
    const parsedJSON = JSON.parse(errorMessages as any);
    alert(parsedJSON[key]);
  }
};

export const registerApi = async ({
  email,
  parentEmail,
  password,
  firstName,
  lastName,
  grade = 0,
  inviteCode = "",
}: RegisterRequest) => {
  try {
    const { data } = await axios.post("/auth/register", {
      email,
      parentEmail,
      password,
      firstName,
      lastName,
      grade,
      inviteCode,
    });

    return data;
  } catch (err: any) {
    const key = err.data.error_id;
    const parsedJSON = JSON.parse(errorMessages as any);
    alert(parsedJSON[key]);
  }
};

export const getCurrentUserApi = async () => {
  try {
    const { data } = await axios.get("/auth");

    return data;
  }catch (err: any) {
    const key = err.data.error_id;
    const parsedJSON = JSON.parse(errorMessages as any);
    alert(parsedJSON[key]);
  }
};

export const getUserByGoogleToken = async (token: string) => {
  try { 
    const { data } = await axios.get(
      "https://www.googleapis.com/oauth2/v3/userinfo?access_token=" + token
    );
    return data;
  } catch (err: any) {
    const key = err.data.error_id;
    const parsedJSON = JSON.parse(errorMessages as any);
    alert(parsedJSON[key]);
  }
};

export const getUserByEmail = async (email: string) => {
  try {
    const { data } = await axios.get("/auth/checkemail", {
      params: {
        email,
      },
    });
    return data;
  } catch (err: any) {
    const key = err.data.error_id;
    const parsedJSON = JSON.parse(errorMessages as any);
    alert(parsedJSON[key]);
  }
};
