import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import HeroAvater from "../assets/images/hero-avater.svg";
import GameThumb from "../assets/images/game-thumb.svg";
import BookIcon from "../assets/images/book.png";
import Calculator from "../assets/images/calculator.svg";
import Logo from "../assets/images/logo.png";
import Navbar from "../components/Navbar";
import ClockIcon from "../assets/images/clock.svg";
import SearchAnimation from "../assets/images/search.svg";
import CupImage from "../assets/images/cup.webp";
import BgImage from "../assets/images/bg1.png";

import {
  startGameApi,
  answerApi,
  endGameApi,
  currentGameApi,
  addThumbUpApi,
} from "../api";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsDown, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { useAppSelector } from "../redux/hooks";

type Question = {
  id: number;
  question: string;
  answers: {
    answer: string;
    id: number;
  }[];
};

export default function Games() {
  const navigate = useNavigate();
  const [isStartedGame, setIsStartedGame] = useState(false);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [gameId, setGameId] = useState<number | null>(null);
  const [difficulty, setDifficulty] = useState(1);
  const [timer, setTimer] = useState(
    Math.floor(Math.random() * (70 - 40 + 1)) + 40
  ); //ezt kicserelni az jsonokben megadott idore
  const [isLoading, setIsLoading] = useState(false); //toltokepernyo
  const [isSendFeedback, setIsSendFeedback] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const currentUser = useAppSelector((state) => state.currentUser.currentUser);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    return () => clearInterval(countdown);
  }, [currentQuestionIndex]);

  useEffect(() => {
    if (timer === 0) {
      handleAnswerOptionClick(-1); // -1 vagy bármi ami rossz
    }
  }, [timer]);

  const handleAnswerOptionClick = async (answerId: number) => {
    if (!gameId) return;

    const taskId = questions[currentQuestionIndex].id;
    await answerApi(gameId, taskId, answerId);

    const nextQuestionIndex = currentQuestionIndex + 1;
    if (nextQuestionIndex < questions.length) {
      setCurrentQuestionIndex(nextQuestionIndex);
      setIsSendFeedback(false);
      setTimer(60);
    } else {
      await endGameApi(gameId);
      navigate(`/results/${gameId}`);
    }
  };

  const handleLoadingAndStartGame = async (isLongGame: boolean) => {
    setIsLoading(true);

    const randomDelay = Math.random() * (10000 - 2000) + 2000;

    setTimeout(async () => {
      setIsLoading(false);
      await handleStartGame(isLongGame);
    }, randomDelay);
  };

  const handleStartGame = async (isLongGame: boolean) => {
    const taskCount = isLongGame ? 6 : 3;
    const startGameRequest = await startGameApi(taskCount, difficulty);
    setIsLoading(false);
    if (startGameRequest && startGameRequest.game && startGameRequest.tasks) {
      setGameId(startGameRequest.game.id);
      setQuestions(startGameRequest.tasks);
      setIsStartedGame(true);
    } else if (startGameRequest && startGameRequest.message) {
      const currentGame = await currentGameApi();
      if (currentGame && currentGame.game) {
        setGameId(currentGame.game.id);
        setQuestions(currentGame.tasks);
        setIsStartedGame(true);
      }
    }
  };

  const handleAddThumbUp = async (questionId: number) => {
    if (isSendFeedback) return;

    await addThumbUpApi(questionId);

    setIsSendFeedback(true);

    toast.success("Köszönjük a visszajelzést!", {
      position: "bottom-left",
    });
  };

  const handleAddThumbDown = async (questionId: number) => {
    if (isSendFeedback) return;

    await addThumbUpApi(questionId);

    setIsSendFeedback(true);

    toast.success("Köszönjük a visszajelzést!", {
      position: "bottom-left",
    });
  };

  if (isLoading) {
    return (
      <div
        className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-t from-blue-500 to-white pb-10"
        style={{ backgroundImage: `url(${BgImage})`, backgroundSize: "cover" }}
      >
        <img
          src={SearchAnimation}
          alt="Ellenfél keresése"
          className="w-32 h-32 animate-bounce"
        />
        <p className="text-3xl lg:text-5xl font-bold mb-10 text-white">
          Ellenfél keresése...
        </p>
      </div>
    );
  }

  if (isStartedGame) {
    return (
      <>
        <div
          className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-t from-blue-500 to-white pb-10"
          style={{
            backgroundImage: `url(${BgImage})`,
            backgroundSize: "cover",
          }}
        >
          <div className="text-center">
            <img src={HeroAvater} alt="Avatar" className="w-24 h-24 mx-auto" />
          </div>

          <div className="bg-white p-10 rounded-xl shadow-xl w-full max-w-2xl relative">
            <div className="absolute top-0 right-0 mt-4 flex items-center justify-between w-full px-5">
              <div className="flex justify-between items-center gap-6">
                <FontAwesomeIcon
                  icon={faThumbsUp}
                  className="w-[2rem] h-[2rem] text-green-400 cursor-pointer"
                  onClick={() =>
                    handleAddThumbUp(questions[currentQuestionIndex].id)
                  }
                />

                <FontAwesomeIcon
                  icon={faThumbsDown}
                  className="w-[2rem] h-[2rem] text-red-400 cursor-pointer"
                  onClick={() =>
                    handleAddThumbDown(questions[currentQuestionIndex].id)
                  }
                />
              </div>
              <div className="flex items-center gap-1">
                <img
                  src={ClockIcon}
                  alt="Óra ikon"
                  className="h-6 w-6 text-blue-500 mr-2"
                />
                <span className="text-xl font-semibold">{timer} másodperc</span>
              </div>
            </div>
            <img
              src={GameThumb}
              alt="Game Thumbnail"
              className="w-16 h-16 mx-auto mb-8 mt-5"
            />
            <div className="mb-8">
              <div className="mb-2 text-center text-xl font-semibold">
                Kérdés {currentQuestionIndex + 1}/{questions.length}
              </div>
              <div className="text-center text-2xl text-gray-800">
                {questions[currentQuestionIndex].question}
              </div>
            </div>
            <div className="grid gap-4">
              {questions[currentQuestionIndex].answers.map((option, index) => (
                <button
                  key={index}
                  onClick={() => handleAnswerOptionClick(option.id)}
                  className="w-full py-3 px-6 text-lg font-medium text-white bg-gradient-to-r from-blue-600 to-blue-400 rounded-lg hover:from-blue-700 hover:to-blue-500 shadow-md transition duration-300 ease-in-out transform hover:-translate-y-1"
                >
                  {option.answer}
                </button>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div
        className="min-h-screen flex flex-col justify-center items-center bg-gradient-to-t from-blue-500 via-blue-300 to-white"
        style={{ backgroundImage: `url(${BgImage})`, backgroundSize: "cover" }}
      >
        <header className="absolute top-0 left-0 w-screen flex justify-center items-center">
          <div className="container">
            <div className="heaer_wrapper">
              <Link to="/" className="logo">
                <img src={Logo} alt="logo" />
              </Link>
              <div className="header-nav">
                <div className={`navigation ${isMenuOpen ? "open" : ""}`}>
                  <Navbar />
                </div>
                {currentUser && !!currentUser.user ? (
                  <button
                    className="btn btn_primary"
                    onClick={() => {
                      localStorage.removeItem("token");
                      localStorage.removeItem("expiresAt");

                      window.location.reload();
                    }}
                  >
                    Kilépés
                  </button>
                ) : (
                  <Link to="/login" className="btn btn_primary">
                    Belépés
                  </Link>
                )}
                <div
                  className={`moible_bar ${isMenuOpen ? "active" : ""}`}
                  onClick={toggleMenu}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </header>
        <h1 className="text-center text-3xl lg:text-5xl font-bold mb-10 text-white mt-24">
          Válassz játékot
        </h1>
        <div className="flex w-full justify-around items-center flex-col gap-10">
          <div className="flex items-center gap-5">
            <button
              className={`text-xl font-bold py-2 px-4 rounded ${
                difficulty === 1 ? "bg-blue-700" : "bg-gray-200"
              } transition duration-300 ease-in-out transform hover:-translate-y-1`}
              onClick={() => setDifficulty(1)}
            >
              Könnyű
            </button>
            <button
              className={`text-xl font-bold py-2 px-4 rounded ${
                difficulty === 2 ? "bg-green-700" : "bg-green-200"
              } transition duration-300 ease-in-out transform hover:-translate-y-1`}
              onClick={() => setDifficulty(2)}
            >
              Közepes
            </button>
            <button
              className={`text-xl font-bold py-2 px-4 rounded ${
                difficulty === 3 ? "bg-red-500" : "bg-gray-200"
              } transition duration-300 ease-in-out transform hover:-translate-y-1`}
              onClick={() => setDifficulty(3)}
            >
              Nehéz
            </button>
          </div>
          <div className="flex items-center gap-10 flex-col lg:flex-row">
            <div className="bg-white text-blue-800 w-64 h-80 px-6 py-8 flex flex-col justify-between items-center rounded-lg shadow-lg hover:shadow-2xl transform transition duration-300 ease-in-out hover:-translate-y-2">
              <div className="flex items-center justify-center">
                <img src={CupImage} alt="Cup" className="w-10 h-10 mr-2" />
                <span className="text-2xl font-bold">
                  {difficulty === 1 ? "3" : difficulty === 2 ? "5" : "7"}
                </span>
              </div>
              <img
                src={BookIcon}
                alt="Rövid játék"
                className="w-20 h-20 mb-5"
              />
              <h2 className="text-2xl font-bold">Rövid játék</h2>
              <p>3 kérdés - 3 válasz</p>
              <button
                className="btn btn-primary hover:bg-blue-400 text-white font-bold py-2 px-4 rounded"
                onClick={() => handleLoadingAndStartGame(false)}
              >
                Játék indítása
              </button>
            </div>
            <div className="bg-white text-blue-800 w-64 h-80 px-6 py-8 flex flex-col justify-between items-center rounded-lg shadow-lg hover:shadow-2xl transform transition duration-300 ease-in-out hover:-translate-y-2">
              <div className="flex items-center justify-center">
                <img src={CupImage} alt="Cup" className="w-10 h-10 mr-2" />
                <span className="text-2xl font-bold">
                  {difficulty === 1 ? "6" : difficulty === 2 ? "8" : "10"}
                </span>
              </div>
              <img
                src={Calculator}
                alt="Hosszú játék"
                className="w-20 h-20 mb-5"
              />
              <h2 className="text-2xl font-bold">Hosszú játék</h2>
              <p>6 kérdés - 6 válasz</p>
              <button
                className="btn btn-primary hover:bg-blue-400 text-white font-bold py-2 px-4 rounded"
                onClick={() => handleLoadingAndStartGame(true)}
              >
                Játék indítása
              </button>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center bg-gray-100 p-4 rounded-lg shadow-inner mt-8 mb-8">
          <h2 className="text-lg font-medium">
            A Te meghívó kódod:{" "}
            <span className="font-bold text-blue-600">
              {currentUser ? currentUser.user.invite_code : "Ismeretlen"}
            </span>
          </h2>
          <p className="text-sm text-gray-700 mt-2 text-center">
            Ez a Te kódod, ha ezt használja más a regisztrációnál, akkor
            mindketten 50-50 kupát kaptok.
          </p>
        </div>
      </div>
    </>
  );
}
