import { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import WheelComponent from "../components/WheelComponent";
import { useWindowSize } from "react-use";
import Confetti from "react-confetti";
import { Dialog, Transition } from "@headlessui/react";
import { toast } from "react-toastify";
import Navbar from "../components/Navbar";
import { useAppSelector } from "../redux/hooks";
import Logo from "../assets/images/logo.png";
import { addCupsToUser } from "../api";
import BgImage from "../assets/images/bg1.png";


const segColors = [
  "#ee1c24",
  "#3cb878",
  "#f6989d",
  "#00aef0",
  "#f26522",
  "#000000",
  "#e70697",
  "#fff200",
];

const segments = [
  "0 kupa",
  "1 nyert",
  "2 kupa",
  "3 kupa",
  "6 nyert",
  "8 kupa",
  "9 kupa",
  "10 kupa",
];

const chancesSegments = [0.2, 0.125, 0.125, 0.125, 0.125, 0.125, 0.05, 0.05];

const cupsPrizes = [0, 1, 2, 3, 6, 8, 9, 10];

const chooseWinnerFromSegments = () => {
  const segmentsWithChances = segments.map((segment, index) => ({
    segment,
    chance: chancesSegments[index],
  }));

  const random = Math.random();

  let currentChance = 0;

  for (let i = 0; i < segmentsWithChances.length; i++) {
    const segment = segmentsWithChances[i];

    if (random >= currentChance && random <= currentChance + segment.chance) {
      return segment.segment;
    }

    currentChance += segment.chance;
  }

  return segmentsWithChances[0].segment;
};

export default function SpinningWheel() {
  const navigate = useNavigate();
  const { width, height } = useWindowSize();

  const currentUser = useAppSelector((state) => state.currentUser.currentUser);

  const [isConfetti, setIsConfetti] = useState<boolean>(false);
  const [isOpenModalWin, setIsOpenModalWin] = useState<boolean>(false);
  const [isOpenModalLose, setIsOpenModalLose] = useState<boolean>(false);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const [prizeName, setPrizeName] = useState<string>("");

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onFinished = async (winner: string) => {
    const segmentId = segments.indexOf(winner);
    const saveRequest = await addCupsToUser(cupsPrizes[segmentId]);

    if (saveRequest && saveRequest.status === 1) {
      if (winner === "Nem nyert") {
        const audio = new Audio("/lose.mp3");
        audio.play();

        setTimeout(() => {
          setIsOpenModalLose(true);
        }, 4000);
      } else {
        const audio = new Audio("/celebration2.mp3");
        audio.play();

        setIsConfetti(true);
        setPrizeName(winner);

        setTimeout(() => {
          setIsOpenModalWin(true);
        }, 2000);
      }
    } else {
      toast.error(saveRequest.message, {
        position: "bottom-left",
      });
    }
  };

  const leavePage = () => {
    setIsOpenModalWin(false);
    setIsOpenModalLose(false);

    navigate("/");
  };

  return (
    <>
      <Transition appear show={isOpenModalWin} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-3xl text-center font-bold text-gray-900 leading-9"
                  >
                    Gratulálunk! &#x1F973;
                  </Dialog.Title>

                  <Dialog.Description className="text-center mt-6 text-xl text-gray-500">
                    A szerencsekeréken pörgetve nyertél valamit!{" "}
                    <span className="font-bold">
                      A nyereményed: {prizeName}
                    </span>
                  </Dialog.Description>

                  <div className="mt-12 flex w-full justify-center items-center">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-red-600 px-10 py-2 text-sm font-bold text-white hover:bg-red-400 focus:outline-none"
                      onClick={leavePage}
                    >
                      Kilépés
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition appear show={isOpenModalLose} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-3xl text-center font-bold leading-9 text-gray-900"
                  >
                    Sajnáljuk! &#x1F625;
                  </Dialog.Title>

                  <Dialog.Description className="text-center mt-6 text-xl text-gray-500">
                    A szerencsekeréken pörgetve nem nyertél semmit!
                  </Dialog.Description>

                  <div className="mt-12 flex w-full justify-center items-center">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-red-600 px-10 py-2 text-sm font-bold text-white hover:bg-red-400 focus:outline-none"
                      onClick={leavePage}
                    >
                      Kilépés
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <div className="min-h-screen flex flex-col justify-center items-center w-screen overflow-hidden bg-gradient-to-t from-blue-500 via-blue-300 to-white" style={{ backgroundImage: `url(${BgImage})`, backgroundSize: "cover" }}>
        <header className="absolute top-0 left-0 w-screen flex justify-center items-center">
          <div className="container">
            <div className="heaer_wrapper">
              <Link to="/" className="logo">
                <img src={Logo} alt="logo" />
              </Link>
              <div className="header-nav">
                <div className={`navigation ${isMenuOpen ? "open" : ""}`}>
                  <Navbar />
                </div>
                {currentUser && !!currentUser.user ? (
                  <button
                    className="btn btn_primary"
                    onClick={() => {
                      localStorage.removeItem("token");
                      localStorage.removeItem("expiresAt");

                      window.location.reload();
                    }}
                  >
                    Kilépés
                  </button>
                ) : (
                  <Link to="/login" className="btn btn_primary">
                    Belépés
                  </Link>
                )}
                <div
                  className={`moible_bar ${isMenuOpen ? "active" : ""}`}
                  onClick={toggleMenu}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </header>

        <WheelComponent
          segments={segments}
          segColors={segColors}
          winningSegment={chooseWinnerFromSegments()}
          onFinished={(winner: string) => onFinished(winner)}
          primaryColor="#4E4F50"
          contrastColor="white"
          buttonText="Pörgetés"
          isOnlyOnce={false}
          size={
            window.innerWidth > 1000 ? 250 : window.innerWidth > 600 ? 220 : 190
          }
          upDuration={200}
          downDuration={300}
          fontFamily="Arial"
        />
        <p className="text-center text-white font-bold">
          Minden nap maximum 1 pörgetés!
        </p>

        {isConfetti && <Confetti width={width} height={height} />}
      </div>
    </>
  );
}
